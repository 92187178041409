import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { isEmpty, forEach, replace } from 'lodash'
import LanguageDetector from 'i18next-browser-languagedetector'

import de from './messages/de.json'
import en from './messages/en.json'
import en_ae from './messages/en-AE.json'
import en_my from './messages/en-my.json'
import en_qa from './messages/en-QA.json'
import en_us from './messages/en-us.json'
import es from './messages/es.json'
import es_mx from './messages/es-mx.json'
import fr from './messages/fr.json'
import fr_ma from './messages/fr-MA.json'
import ko from './messages/ko.json'
import it from './messages/it.json'
import ja from './messages/ja.json'
import lt from './messages/lt.json'
import nl from './messages/nl.json'
import pt_br from './messages/pt-br.json'
import pt from './messages/pt.json'
import ru from './messages/ru.json'
import th from './messages/th.json'
import tr from './messages/tr.json'
import vi from './messages/vi.json'
import zh_cn from './messages/zh-cn.json'
import zh_hk from './messages/zh-hk.json'
import zh_tw from './messages/zh-tw.json'

const resources = {
  de: { translation: de },
  en: { translation: en },
  en_ae: { translation: en_ae },
  en_my: { translation: en_my },
  en_qa: { translation: en_qa },
  en_us: { translation: en_us },
  es: { translation: es },
  es_mx: { translation: es_mx },
  fr: { translation: fr },
  fr_ma: { translation: fr_ma },
  it: { translation: it },
  ja: { translation: ja },
  ko: { translation: ko },
  lt: { translation: lt },
  nl: { translation: nl },
  pt: { translation: pt },
  pt_br: { translation: pt_br },
  ru: { translation: ru },
  th: { translation: th },
  tr: { translation: tr },
  vi: { translation: vi },
  zh_cn: { translation: zh_cn },
  zh_hk: { translation: zh_hk },
  zh_tw: { translation: zh_tw },
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // detection: options,
    detection: { order: ['path', 'navigator'] },
    debug: true,
    resources,
    compatibilityJSON: 'v3',
    load: 'currentOnly', // languageOnly : load only fr when locale is fr-CA
    react: {
      useSuspense: false,
    },
    //lng: 'en',
    fallbackLng: ['en', 'fr'],
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
  })

export default i18n

export const tWithParams = (str, params) => {
  let text = str
  forEach(params, (value, key) => {
    text = replace(text, new RegExp(`{{${key}}}`, 'g'), value)
  })
  return text
}

export const graphicalCharterLink = async () => {
  try {
    const tmp = i18n.t('graphical-charter-html-content')
    if (!isEmpty(tmp)) {
      const pdf = await import(`./contents/${tmp}`)
      return pdf.default
    }
  } catch (error) {
    // console.log('graphicalCharterLink ', error)
  }
  return null
}

export const tHtml = async (page, params) => {
  let p = params
  if (!p || !typeof p === 'object') {
    p = {}
  }
  if (!isEmpty(page)) {
    const __html = await import(`./contents/${page}`)
    const __guidelines = await graphicalCharterLink()
    return tWithParams(__html.default, { ...p, guidelines: __guidelines })
  }
  return ''
}
