import React, { useRef, useState } from 'react'
import { Box } from '@chakra-ui/layout'
import { Image, Text, Flex, Spacer } from '@chakra-ui/react'
import { ButtonSecondary, ButtonPrimary } from '../Base'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useMediaQuery,
} from '@chakra-ui/react'
import * as API from '@api'
import { useTranslation } from 'react-i18next'
import { noImage } from '@assets/svg/'

const ImageCard = ({
  id,
  image,
  title,
  subject,
  copyright,
  otherProvider,
  poiName,
  withActions = true,
  reloadHandler = () => {},
  // editHandler = () => {}
}) => {
  const { t } = useTranslation()
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const onDeleteClose = () => setIsDeleteOpen(false)
  const [isLessThan18em] = useMediaQuery('(max-width: 18em)')
  const cancelRef = useRef(null)
  const deleteAsset = async () => {
    await API.deleteAsset(id, copyright, otherProvider, poiName)
    reloadHandler()
    onDeleteClose()
  }
  return (
    <Box
      w="100%"
      bg="white"
      minHeight={withActions ? ['147px', '340px'] : ['83px', '270px']}
      borderRadius={4}
      boxShadow="lg"
      m={[0, 4]}
      ml={[0]}>
      <Box display={['flex', 'unset']} alignItems="flex-start">
        <Image
          src={image}
          w={['72px', '100%']}
          h={['83px', '200px']}
          borderTopRightRadius={[0, 4]}
          borderTopLeftRadius={4}
          borderBottomLeftRadius={withActions ? 0 : [4, 0]}
          objectFit="cover"
          fallbackSrc={noImage}
        />
        <Box w="100%" maxW={isLessThan18em ? '166px' : ['245px', '290px']} p={4} h={['74px']}>
          <Flex>
            <Text isTruncated fontSize={16} maxW={['140px']}>
              {title}
            </Text>
            <Spacer />
            <Text isTruncated fontSize={16} maxW={['100px']}>
              {subject}
            </Text>
          </Flex>
          <Text maxW={isLessThan18em ? '166px' : '190px'} isTruncated fontSize={12}>
            {copyright}
          </Text>
        </Box>
      </Box>
      {withActions && (
        <>
          <Flex
            direction={['row', 'column']}
            borderTopWidth={1}
            borderTopColor="#E8E8E8"
            pr={5}
            pl={5}>
            {/* <ButtonSecondary w={['125px', '100%']} h="32px" onClick={editHandler} mt={4}>
          {t('update')}
        </ButtonSecondary>*/}
            <Spacer />
            <ButtonSecondary
              w={['125px', '100%']}
              h="32px"
              onClick={() => setIsDeleteOpen(true)}
              mt={4}>
              {t('delete')}
            </ButtonSecondary>
          </Flex>
          <AlertDialog
            colorScheme="red"
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={onDeleteClose}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {t('delete-image')}
                </AlertDialogHeader>
                <AlertDialogBody>{t('delete-image-confirm-msg')}</AlertDialogBody>
                <AlertDialogFooter>
                  <ButtonPrimary ref={cancelRef} reverse={true} onClick={onDeleteClose}>
                    {t('cancel')}
                  </ButtonPrimary>
                  <ButtonPrimary colorScheme="red" onClick={deleteAsset} ml={3}>
                    {t('delete')}
                  </ButtonPrimary>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
    </Box>
  )
}

export default ImageCard
