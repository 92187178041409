import React from 'react'
import { Select as SelectChakraUI } from '@chakra-ui/react'
import { MdArrowDropDown } from 'react-icons/md'

const Select = (props) => {
  return (
    <SelectChakraUI
      borderColor="gray.300"
      focusBorderColor="gray.400"
      icon={<MdArrowDropDown />}
      iconColor="gray.300"
      {...props}
    />
  )
}

export default Select
