import React from 'react'
import { Box } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/react'
import logo from '@assets/img/logo.png'
import { Link as RouteLink } from 'react-router-dom'

const Header = () => {
  return (
    <Box display="flex" p={5} w="100%" bg="michelinPrimary" position="sticky" top="0" zIndex="2">
      <RouteLink to={`/`}>
        <Image src={logo} w="230px" />
      </RouteLink>
    </Box>
  )
}

export default Header
