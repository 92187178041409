import React from 'react'
import { Box } from '@chakra-ui/layout'
import { Image, Text, Flex, Spacer } from '@chakra-ui/react'
import { ButtonSecondary } from '../Base'
import { useTranslation } from 'react-i18next'
import { noImage } from '@assets/svg/'

const PendingImageCard = ({ id, image, title, subject, deleteAction = () => {} }) => {
  const { t } = useTranslation()

  return (
    <Flex
      direction={['column', 'row']}
      justifyContent="space-between"
      alignContent="center"
      borderBottom="1px solid #cccccc"
      p="10px"
      mb="10px">
      <Flex>
        <Image
          w={['72px', '150px']}
          h={['83px', '100px']}
          objectFit="cover"
          fallbackSrc={noImage}
          borderRadius={4}
          src={image}
        />
        <Box px="20px">
          <Text fontSize={16}>{title}</Text>
          <Text fontSize={16}>{subject}</Text>
        </Box>
      </Flex>
      <Flex direction={['row', 'column']} borderTopWidth={[1, 0]} borderTopColor="#E8E8E8">
        <Spacer />
        <ButtonSecondary w={['125px', '100%']} h="32px" mt={4} onClick={() => deleteAction(id)}>
          {t('delete')}
        </ButtonSecondary>
      </Flex>
    </Flex>
  )
}

export default PendingImageCard
