import React from 'react'
import { Box } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/react'

const Title = ({ text }) => {
  return (
    <Box mb={4}>
      {/* <Box bg="michelinPrimary" w={54} h={2}></Box> */}
      <Text fontWeight={'400'} fontSize={20} color="#757575">
        {text}
      </Text>
    </Box>
  )
}

export default Title
