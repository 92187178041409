import React, { forwardRef } from 'react'
import { Button as ButtonChakraUI } from '@chakra-ui/react'

import { get } from 'lodash'

const Button = (props, bgColor, textColor, borderColor, ref) => {
  let colors = {
    bg: bgColor,
    text: textColor,
    border: borderColor,
  }
  if (get(props, 'isDisabled')) {
    colors = {
      bg: 'gray.300',
      text: 'white',
      border: 'white',
    }
  }
  return (
    <ButtonChakraUI
      ref={ref}
      color={colors.text}
      bg={colors.bg}
      border="1px"
      borderColor={colors.border}
      _hover={{ bg: colors.border, color: colors.bg, borderColor: colors.bg }}
      fontSize={12}
      {...props}>
      {props.children}
    </ButtonChakraUI>
  )
}

const ButtonPrimary = forwardRef((props, ref) => {
  const { reverse = false, ...rest } = props
  return reverse
    ? Button({ ...rest }, 'white', 'michelinPrimary', 'michelinPrimary', ref)
    : Button({ ...rest }, 'michelinPrimary', 'white', 'white', ref)
})
ButtonPrimary.displayName = 'ButtonPrimary'

const ButtonSecondary = forwardRef((props, ref) => {
  return Button(props, 'white', '#4D4D4D', '#AF8D5A', ref)
})
ButtonSecondary.displayName = 'ButtonSecondary'

export { ButtonPrimary, ButtonSecondary }
