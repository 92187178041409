import React from 'react'
import { Center } from '@chakra-ui/layout'
import { Image, Text, useMediaQuery } from '@chakra-ui/react'
import { notAuthenticatedDesktop, notAuthenticatedMobile } from '@assets/svg'
import { useTranslation } from 'react-i18next'

const AuthenticationError = () => {
  const { t } = useTranslation()
  const [isMobile] = useMediaQuery('(max-width: 479px)')
  return (
    <Center flexDirection={'column'} pt="200">
      <Image src={isMobile ? notAuthenticatedMobile : notAuthenticatedDesktop} />
      <Text
        w={isMobile ? 250 : '100%'}
        textAlign="center"
        fontWeight="400"
        fontFamily="Noto Serif"
        fontSize={['2xl', '4xl']}
        p={10}>
        {t('missing-authentication-token-msg')}
      </Text>
    </Center>
  )
}

export default AuthenticationError
