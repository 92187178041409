import React from 'react'
import { Text, Image, useMediaQuery } from '@chakra-ui/react'
import { Center } from '@chakra-ui/layout'
import { useTranslation } from 'react-i18next'
import { Link as RouteLink } from 'react-router-dom'
import { notAuthenticatedDesktop, notAuthenticatedMobile } from '@assets/svg'
import { ButtonPrimary } from '@components/Base'

const NoMatch = (props) => {
  const { qs = '' } = props
  const { t } = useTranslation()
  const [isMobile] = useMediaQuery('(max-width: 479px)')
  return (
    <Center flexDirection={'column'} pt="200">
      <Image src={isMobile ? notAuthenticatedMobile : notAuthenticatedDesktop} />
      <Text
        as="h3"
        fontWeight="400"
        fontFamily="Noto Serif"
        fontSize={['2xl', '4xl']}
        textAlign="center"
        w={isMobile ? 250 : '100%'}
        p={10}>
        {t('page-not-found')}
      </Text>
      <RouteLink to={`/${qs}`}>
        <ButtonPrimary w="100%">{t('return-home')}</ButtonPrimary>
      </RouteLink>
    </Center>
  )
}

export default NoMatch
