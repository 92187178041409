import React from 'react'

const HtmlContainer = (props) => {
  const { html } = props
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}

export default HtmlContainer
