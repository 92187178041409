import axios from 'axios'

const initAxios = () => {
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_API_KEY
  axios.defaults.headers.common.Accept = 'application/json'
  axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT
  return true
}

export { initAxios }
