import React, { useState, useEffect } from 'react'
import { Box, Center } from '@chakra-ui/layout'
import { Text, Stack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link as RouteLink } from 'react-router-dom'
import { graphicalCharterLink } from '@i18n'

const Footer = () => {
  const { t } = useTranslation()

  const [graphicalCharterPage, setGraphicalCharterPage] = useState(null)

  useEffect(() => {
    graphicalCharterLink().then((pdf) => setGraphicalCharterPage(pdf))
  }, [])

  return (
    <Center bg="white">
      <Box
        w="100%"
        mx={[6, 20, 20, 24, 40]}
        borderTopColor="michelinPrimary"
        borderTopWidth={1}
        pb={[4, 8]}
        pt={[8, 16]}>
        <Text fontSize={[12, 14]} textAlign="center" color="gray.600" lineHeight={2} mb={1}>
          {t('footer-copyright')}
        </Text>
        <Stack
          justify="center"
          spacing={[0, 4]}
          direction="row"
          divider={
            <Text fontSize={14} pl={[2, 4]} pr={[2, 4]}>
              .
            </Text>
          }>
          <RouteLink to={`/legal-notice`} target="_blank">
            <Text fontSize={14} color="gray.600" lineHeight={2}>
              {t('footer-legal-notice')}
            </Text>
          </RouteLink>
          <RouteLink to={`/terms-of-use`} target="_blank">
            <Text fontSize={14} color="gray.600" lineHeight={2}>
              {t('footer-terms-of-use')}
            </Text>
          </RouteLink>
          <RouteLink to={`/privacy-policy`} target="_blank">
            <Text fontSize={14} color="gray.600" lineHeight={2}>
              {t('footer-privacy-policy')}
            </Text>
          </RouteLink>
          {graphicalCharterPage && (
            <RouteLink to={graphicalCharterPage} target="_blank">
              <Text fontSize={14} color="gray.600" lineHeight={2}>
                {t('footer-graphical-charter')}
              </Text>
            </RouteLink>
          )}
        </Stack>
      </Box>
    </Center>
  )
}

export default Footer
