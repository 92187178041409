import React from 'react'
import { Center, Box } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/react'
// import _ from 'lodash'
import { addIcon } from '@assets/svg/'
import { ButtonPrimary } from '@components/Base'
import { useTranslation } from 'react-i18next'

const AddCard = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Box w="100%" bg="michelinPrimary" m={[0, 4]} ml={[0]} borderRadius={4} p={5} boxShadow="lg">
      <Center flexDirection={'column'} h={['77px', '260px']}>
        <Image src={addIcon} boxSize={['40px', '75px']} />
      </Center>
      <ButtonPrimary w="100%" onClick={onClick}>
        {t('add-image')}
      </ButtonPrimary>
    </Box>
  )
}

export default AddCard
