import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/layout'
import { Wrap, WrapItem, Skeleton, useMediaQuery } from '@chakra-ui/react'
import UploadFiles from '@components/Upload/UploadFiles'
import PoiInfo from '@components/PoiInfo'
import Title from '@components/Title'
import HtmlContainer from '@components/HtmlContainer'
import { AddCard, ImageCard } from '@components/Cards'
import { useDisclosure } from '@chakra-ui/hooks'
import { isEmpty, get } from 'lodash'
import { useTranslation } from 'react-i18next'
import * as API from '@api'
import { graphicalCharterLink } from '@i18n'

const Main = ({ user }) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [selectedAsset, setSelectedAsset] = useState(null)
  const [isMobile] = useMediaQuery('(max-width: 479px)')
  const [graphicalCharterPage, setGraphicalCharterPage] = useState(null)

  const getData = async () => {
    const id = get(user, 'Id')
    if (id) {
      const [result] = await API.getAssets(id)
      if (result) {
        setData(get(result, 'items').filter((e) => !isEmpty(e)))
      }
      // if (err) {
      //   console.log('[Main.getData]', err)
      // }
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
    graphicalCharterLink().then((pdf) => setGraphicalCharterPage(pdf))
  }, [])

  return (
    <>
      <PoiInfo user={user} />
      <Box pt={10} pb={10} px={[6, 20, 20, 24, 40]}>
        <UploadFiles
          data={selectedAsset}
          user={user}
          isOpen={isOpen}
          onClose={onClose}
          reloadHandler={() => getData()}
        />
        <Box opacity={isOpen ? 0.1 : 1} pointerEvents={isOpen ? 'none' : 'unset'}>
          <Title text={t('images-title')} />

          <Box color="gray.500" fontSize={'14px'} mb={10}>
            <HtmlContainer html={t('images-subtitle', { guidelines: graphicalCharterPage })} />
          </Box>
          <Wrap>
            <WrapItem w={['100%', '270px']}>
              <AddCard
                onClick={() => {
                  onOpen()
                  window.scrollTo(0, isMobile ? 150 : 200)
                }}
              />
            </WrapItem>
            {loading
              ? [...Array(9).keys()].map((e) => (
                  <WrapItem key={e} w={['100%', '270px']}>
                    <Skeleton
                      startColor="gray.100"
                      endColor="gray.200"
                      w="100%"
                      h={['147px', '340px']}
                      borderRadius={4}
                      boxShadow="lg"
                      m={[0, 4]}
                      ml={[0]}
                    />
                  </WrapItem>
                ))
              : data.map((e) => (
                  <WrapItem key={e.id} w={['100%', '270px']}>
                    <ImageCard
                      id={e.id}
                      title={e.title}
                      image={e.image}
                      subject={t(e.subjectIdentifier)}
                      copyright={e.copyright}
                      otherProvider={e.otherProvider}
                      poiName={get(user, 'poiName')}
                      editHandler={() => {
                        setSelectedAsset({
                          assetId: e.id,
                          title: e.title,
                          subject: e.subjectIdentifier,
                          quality: e.qualityIdentifier,
                          relateddInspecId: e.relateddInspecId,
                          author: e.author,
                          otherProvider: e.otherProvider,
                          uploaderEmail: e.uploaderEmail,
                          uploaderName: e.uploaderName,
                          uploaderPosition: e.uploaderPosition,
                        })
                        onOpen()
                        window.scrollTo(0, isMobile ? 150 : 200)
                      }}
                      reloadHandler={() => getData()}
                    />
                  </WrapItem>
                ))}
          </Wrap>
        </Box>
      </Box>
    </>
  )
}

export default Main
